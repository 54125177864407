import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, QuestionMarkCircleIcon, UserIcon, XIcon } from '@heroicons/react/outline';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCustomerDetails } from '../../api/customer';
import { getCustomerUserAccess } from '../../api/customerUser';
import { AuthenticatedRoutesContext, DefaultLayoutContext } from '../../contexts';
import { useHasCompany } from '../../hooks/useHasCompany';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { useToastAction } from '../../hooks/useToastAction';
import { useTryAwaitAction } from '../../hooks/useTryAwaitAction';
import { config } from '../../utils/config';
import { changeCompany } from '../../utils/oidcUtilities';
import { configuredProducts } from './products';
import useIsNotActiveCustomer from '../../hooks/useIsNotActiveCustomer';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function DefaultLayout({ nav, subNav, children, customClassName }) {
  const authContext = useContext(AuthenticatedRoutesContext);
  const { oidcUser } = useReactOidc();
  const isNotActiveCustomer = useIsNotActiveCustomer();
  const loadAction = useToastAction();
  const [isLoaded, setIsLoaded] = useState(false);
  const hasCompany = useHasCompany(authContext.centerPointUser);
  const { tryAwaitAction } = useTryAwaitAction();
  const isAdmin = useIsAdmin(authContext.centerPointUser);

  const [navigation, setNavigation] = useState([
    {
      name: 'Dashboard',
      href: '/dashboard',
      current: nav === 'dashboard',
      show: !isNotActiveCustomer,
      currentNav: 'dashboard'
    },
    {
      name: 'Plans',
      href: '/plans',
      requireCustomer: true,
      requireAdmin: true,
      current: nav === 'plans',
      currentNav: 'plans',
      show: !isNotActiveCustomer
    }
  ]);

  const userNavigation = [
    { name: 'Settings', href: '/userprofile', show: !isNotActiveCustomer },
    {
      name: 'Change Company',
      href: '#',
      onClick: changeCompany,
      show: authContext?.centerPointUser?.customerIds?.length > 1
    },
    { name: 'New Company', href: '/company/create', show: !isNotActiveCustomer },
    {
      name: 'Sign out',
      href: '#',
      onClick: authContext.onLogout,
      show: true
    }
  ];

  const [profileOverview, setProfileOverview] = useState({
    userFullName: authContext.centerPointUser.fullName,
    userImage: authContext.centerPointUser.userProfileImage,
    companyName: '',
    orgNumber: '',
    country: {},
    currentPlan: {},
    isNotActiveCustomer: isNotActiveCustomer
  });
  
  const [customerDetails, setCustomerDetails] = useState({});
  const [customerUserAccess, setCustomerUserAccess] = useState({});
  const [products, setProducts] = useState(configuredProducts);

  const updateProducts = () => {
    if (!customerUserAccess.productFeatureAccessSelections) {
      return;
    }

    setProducts((previousProducts) => {
      return previousProducts.map((previousProduct) => {
        if (isNotActiveCustomer) {
          previousProduct.hasLicense = true;
        } else {
          previousProduct.hasLicense = customerUserAccess.productFeatureAccessSelections.find(
            (x) => x.productCode === previousProduct.productCode
          )?.usageFeature?.hasAccess;
        }

        return previousProduct;
      });
    });
  };

  const loadCustomer = async () => {
    const [customerDetailsData, customerUserAccessCollection] = await Promise.all([
      tryAwaitAction(() => getCustomerDetails(oidcUser.access_token)),
      tryAwaitAction(() => getCustomerUserAccess())
    ]);

    setCustomerDetails(customerDetailsData);
    setCustomerUserAccess(customerUserAccessCollection || []);
  };

  const loadDefaultData = async () => {
    setIsLoaded(false);
    loadAction.execute(async () => {
      await loadCustomer();
      setIsLoaded(true);
    }, 'Failed to load dashboard');
  };

  useEffect(() => {
    setProfileOverview((prev) => {
      return {
        ...prev,
        companyName: customerDetails.name,
        orgNumber: customerDetails.orgNumber,
        country: customerDetails.country
      };
    });
  }, [customerDetails]);

  useEffect(() => {
    updateProducts();
    setNavigation((prevNavigation) => {
      return prevNavigation.map((prev) => {
        prev.show = true;

        if (prev.requireCustomer && !hasCompany) {
          prev.show = false;
        }

        if (prev.requireAdmin && !isAdmin) {
          prev.show = false;
        }

        if (isNotActiveCustomer) {
          prev.show = false;
        }

        return prev;
      });
    });
  }, [customerUserAccess]);

  useEffect(() => {
    setNavigation((prevNavigation) => {
      return prevNavigation.map((prev) => {
        prev.current = prev.currentNav === nav;
        return prev;
      });
    });
  }, [nav]);

  useEffect(() => {
    if (isLoaded) {
      return;
    }
    loadDefaultData();
  }, []);

  useEffect(() => {
    setProfileOverview((prev) => {
      return {
        ...prev,
        userFullName: authContext.centerPointUser.fullName,
        userImage: authContext.centerPointUser.userProfileImage
      };
    });
  }, [authContext]);

  return (
    <div className="relative min-h-screen flex flex-col bg-gray-50">
      <header className='sticky top-0 z-10'>
        <Disclosure as="nav" className="bg-white border-b border-gray-200 relative z-40">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                  <div className="flex">
                    <div className="flex-shrink-0 flex items-center">
                      <Link to={'/'} className="cursor-pointer">
                        <img className="h-8 w-auto" src={config.logoMain} alt="Metaforce" />
                      </Link>
                    </div>
                    <div className="hidden sm:-my-px sm:ml-6 md:ml-12 sm:flex sm:space-x-8">
                      {navigation.map((item) => {
                        return (
                          item.show && (
                            <Link
                              to={item.href}
                              key={item.name}
                              className={classNames(
                                item.current
                                  ? 'border-brand-pink text-gray-900'
                                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium cursor-pointer'
                              )}
                              aria-current={item.current ? 'page' : undefined}
                            >
                              {item.name}
                            </Link>
                          )
                        );
                      })}
                    </div>
                  </div>

                  <div className="hidden sm:ml-6 sm:flex sm:items-center text-xs">
                    <div className='mr-8'>
                      <a
                        title="Documentation link"
                        target="_blank"
                        className='p-2 group flex space-x-2 text-gray-500 hover:text-brand-pink text-sm font-medium cursor-pointer nav-button align-middle inline-block rounded-full'
                        href={config.docsUrl}
                      >
                        <span>Docs</span>
                        <span className='flex text-white group-hover:text-brand-pink'>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-3 h-3 self-end mb-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                          </svg>

                        </span>
                      </a>
                    </div>
                    <div className="float-right mr-8">
                      <div className="font-semibold">{profileOverview.userFullName}</div>
                      <div>
                        {profileOverview.companyName} - {profileOverview.orgNumber}
                      </div>
                    </div>
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button
                              className={`p-1 rounded-full text-black hover:text-white focus:outline-none hover:bg-brand-pink-hover active:bg-brand-pink-hover nav-button align-middle ${open ? 'active' : ''
                                }`}
                            >
                              <span className="sr-only">Open user menu</span>
                              {profileOverview.userImage && (
                                <img
                                  className="inline-block h-6 w-6 rounded-full"
                                  src={profileOverview.userImage}
                                  alt=""
                                />
                              )}
                              {!profileOverview.userImage && (
                                <UserIcon className="h-6 w-6" aria-hidden="true" />
                              )}
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              {userNavigation.map(
                                (item) =>
                                  item.show && (
                                    <Menu.Item key={item.name}>
                                      {({ active }) => (
                                        <Link
                                          to={item.href}
                                          onClick={(e) => item.onClick?.call()}
                                          className={classNames(
                                            active ? 'bg-red-200' : '',
                                            'block px-4 py-2 text-sm text-gray-700'
                                          )}
                                        >
                                          {item.name}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                  )
                              )}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                  <div className="-mr-2 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
              <Disclosure.Panel className="sm:hidden">
                <div className="pt-2 pb-3 space-y-1">
                  {navigation.map((item) => {
                    return (
                      item.show && (
                        <Disclosure.Button
                          className={classNames(
                            item.current
                              ? 'border-brand-pink text-brand-pink'
                              : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                            'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                          )}
                        >
                          <Link
                            key={item.name}
                            to={item.href}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </Link>
                        </Disclosure.Button>
                      )
                    );
                  })}
                </div>
                <div className="pt-4 pb-3 border-t border-gray-200">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      {profileOverview.userImage && (
                        <img
                          className="inline-block h-6 w-6 rounded-full"
                          src={profileOverview.userImage}
                          alt=""
                        />
                      )}
                      {!profileOverview.userImage && (
                        <UserIcon className="h-6 w-6" aria-hidden="true" />
                      )}
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">
                        {profileOverview.userFullName}
                      </div>
                      <div className="text-sm font-medium text-gray-500">
                        {profileOverview.companyName} - {profileOverview.orgNumber}
                      </div>
                    </div>
                    {/* <button className="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                          <span className="sr-only">View notifications</span>
                                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                                      </button> */}
                  </div>
                  <div className='px-4 pt-4'>
                    <a
                      title="Documentation link"
                      target="_blank"
                      className='flex space-x-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100'
                      href={config.docsUrl}
                    >
                      <span>Docs</span>
                      <span className='flex'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4 self-end mb-1">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                        </svg>
                      </span>
                    </a>
                  </div>
                  <div className="mt-2 space-y-1">
                    {userNavigation.map(
                      (item) =>
                        item.show && (
                          <Disclosure.Button className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100">
                            <Link
                              key={item.name}
                              onClick={(e) => {
                                item.onClick?.call();
                              }}
                              to={item.href}
                              className=""
                            >
                              {item.name}
                            </Link>
                          </Disclosure.Button>
                        )
                    )}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </header>
      
      <DefaultLayoutContext.Provider
        value={{
          profileOverview: profileOverview,
          customerDetails: customerDetails,
          products: products,
          isAdmin: isAdmin,
          hasCompany,
          triggerContextChange: () => loadCustomer()
        }}
      >
        <div className={classNames('py-10 bg-gray-50', customClassName)}>
          {isLoaded && children}
        </div>
      </DefaultLayoutContext.Provider>
    </div>
  );
}
